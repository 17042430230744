#schedule {
  #days {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    @include moreThan(md) {
      grid-template-columns: repeat(2, 1fr);
    }
    grid-gap: $gutter;
    .day {
      .header {
        border: 1px solid $grey-light;
        border-right: none;
        border-left: none;
        padding: $gutter/2;
        text-transform: uppercase;
        margin-bottom: $gutter/2;
      }
      .header {
        padding-right: $gutter/4;
        padding-left: $gutter/4;
        border-bottom: 1px solid $black;
        border-top: 1px solid $black;
        p {
          margin: 0;
        }
      }
      .slot {
        &:hover {
          background-color: $black;
          color: white;
        }
        p {
          margin: 0;
          font-size: $font-size-sm;
        }
        display: grid;
        grid-template-columns: 1fr 2fr 1fr;
        padding: $gutter/3;
        padding-right: $gutter/4;
        padding-left: $gutter/4;
      }
      .slot {
        #live {
        }
        padding: inherit $gutter/8;
      }
    }
  }
}
