h1 {
  font-size: $font-size-xl;
}

h2 {
  font-size: $font-size-lg;
}

h3 {
  font-size: $font-size-md;
}

h1,
h2,
h3,
h3 {
  font-weight: normal;
  margin-bottom: $gutter;
}

button {
  font-weight: normal;
  font-family: inherit;
  outline: none;
  border: none;
  background-color: transparent;
}

a,
a:hover,
a:active {
  text-decoration: none;
  color: inherit;
}

.pareto {
  font-family: "ParetoTriangular";
}

.favorit {
  font-family: "favorit";
}

small {
  font-size: $font-size-sm;
}

p {
  font-size: $font-size-md;
  line-height: 1.2;
  margin-bottom: $gutter/2;
}
