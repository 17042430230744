.loadMore {
  display: flex;
  justify-content: center;
  .loader {
    height: 30vh;
  }
  button {
    font-size: $font-size-xl;
    text-transform: uppercase;
    font-family: "ParetoTriangular";
    border: none;
    outline: none;
    background-color: transparent;
  }
}
