.tag {
  font-size: $font-size-sm;
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  margin: 9px;
  margin-left: 0;
  margin-top: 0;
  padding: 3px;
  padding-left: 9px;
  padding-right: 9px;
  border: 1px solid $black;
}
