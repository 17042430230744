#festival {
  .header {
    display: flex;
    border-style: solid;
    border-color: $black;
    border-top-width: 1px;
    border-bottom-width: 1px;
    height: $bar-height;
    justify-content: center;
    align-items: center;
   
    h1 {
      font-size: $font-size-md;
      margin: 0;
      padding: $gutter;
    }
  }
  section {
    margin-bottom: $gutter * 2;
  }
  .filters {
    display: block;
    .details,
    .pages {
      display: flex;
      justify-content: center;
      .page {
        text-transform: capitalize;
      }
      li {
        padding: $gutter;
        font-size: $font-size-sm;
        button {
          padding: $gutter/4 $gutter/2;
          border-radius: $gutter;
          &:hover {
            cursor: pointer;
          }
          &.active {
            background-color: $black;
            color: white;
          }
          outline: none;
          font-size: $font-size-md;
          font-weight: normal;
          background-color: transparent;
          border: none;
        }
      }
    }
    .studios {
      border-color: black;
      border-width: 1px;
      border-top-style: solid;
      border-bottom-style: solid;
      p {
        margin: 0;
      }
    }
    .pages {
      border-color: black;
      border-width: 1px;
      border-bottom-style: solid;
      @include lessThan(lg) {
        & {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          padding: $gutter/2;
          li {
            text-align: center;
            padding: 0;
          }
        }
      }
    }
    a {
      background-color: transparent;
      border: none;
      font-size: $font-size-md;
      padding: 0 $gutter/2;
      outline: none;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
      &.active {
        text-decoration: underline;
      }
    }
  }
  .entries {
    padding: $gutter;
  }

  .venue, .event {
    padding: $gutter;
    .content {
      display: grid;
      grid-gap: $gutter;
      grid-template-columns: 1fr 1fr;
      @include lessThan(lg) {
        grid-template-columns: 1fr;
      }
      .left {
        img {
          position: sticky;
          position: -webkit-sticky;
          top: $bar-height;
        }
      }
      & > .right {
        .details {
          border-top: 1px solid black;
          border-bottom: 1px solid black;
          padding: $gutter;
          & > * {
            margin-bottom: $gutter;
          }
          .date {
            p {
              font-size: $font-size-sm;
            }
          }
          h1,
          h2 {
            font-size: $font-size-lg;
            font-weight: 300;
            text-transform: uppercase;
          }
          h2 {
            font-size: $font-size-md;
          }
        }
        .description {
          padding: $gutter;
          text-align: center;
          p {
            margin-top: $gutter * 2;
            margin-bottom: $gutter * 2;
            padding: 0 $gutter * 4;
            @include lessThan(md) {
              padding: 0;
            }
            text-align: center;
          }
        }
      }
    }
  }
  .tag {
    font-size: $font-size-md;
  }
}
