#schedulePage {
  .header {
    border-top: 1px solid $black;
    padding: $gutter * 2 $gutter;
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    h1 {
      margin: 0;
      font-family: "ParetoTriangular";
    }
    p {
      font-size: $font-size-sm;
      margin: 0;
      margin-left: $gutter;
    }
  }
  #schedule {
    padding: 0 $gutter;
  }
}
