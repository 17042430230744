#carousel {
  .slick-list {
  }
  .carouselEntry {
    .carouselContent {
      height: 300px;
      @include moreThan(md) {
        height: 400px;
      }
      @include moreThan(lg) {
        height: 600px;
      }
      position: relative;
      text-align: center;
      img {
        object-fit: cover;
        height: 100%;
      }
      .infos {
        @extend .favorit;
        & > * {
          margin-bottom: $gutter;
        }
        .title {
          text-transform: uppercase;
          font-size: $font-size-md;
        }
        .headline {
          display: -webkit-box;
          font-size: $font-size-sm;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 15;
          -webkit-box-orient: vertical;
        }
        .tags {
          display: flex;
          flex-flow: row wrap;
          justify-content: center;
        }
        background-color: white;
        padding: $gutter;
        max-width: 40vw;
        @include lessThan(md) {
          .tags {
            display: none;
          }
        }
        @include moreThan(md) {
          max-width: 35vw;
          .tags {
            // display: inherit;
          }
        }
        @include moreThan(lg) {
          max-width: 20vw;
        }
        position: absolute;
        right: $gutter;
        top: $gutter;
      }
    }
  }
}
