#search {
  .header {
    margin: $gutter;
  }
  section {
    border-style: solid;
    border-color: $black;
    border-bottom-width: 1px;
    padding-top: $gutter;
    padding-bottom: $gutter;
    margin: $gutter;
    
    h1 {
      @extend .pareto;
      font-size: $font-size-lg;
      text-transform: uppercase;
    }
  }
}