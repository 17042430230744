#fourOFour {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;
  img {
    max-width: 300px;
    height: auto;
  }
}
