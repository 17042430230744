.loader {
  // text-align: center;
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 300px;
  }
}
