#discover {
  .header {
    display: flex;
    border-style: solid;
    border-color: $black;
    border-top-width: 1px;
    border-bottom-width: 1px;
    height: $bar-height;
    justify-content: center;
    align-items: center;
   
    h1 {
      font-size: $font-size-md;
      margin: 0;
    }
  }
  & > * {
    margin-bottom: $gutter;
  }
  .episodes {
    padding: $gutter;
  }
}
