.pagination {
  font-size: $font-size-sm;
  margin-top: $gutter;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  .previous {
  }
  .middle {
    display: flex;
    justify-content: center;
  }
  .next {
    display: flex;
    justify-content: flex-end;
  }
}
