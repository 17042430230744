.playButton {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.fade {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.4s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#player {
  z-index: 3;
  position: fixed;
  bottom: 0;
  width: 100vw;
  #stream {
    @extend .fade;
    text-transform: uppercase;
    display: grid;
    grid-template-columns: 8fr 1fr;
    @include lessThan(md) {
      grid-template-columns: 1fr;
    }
    align-items: center;
    height: 60px;
    .left {
      & > * {
        padding: 0 $gutter;
      }
      height: 100%;
      background-color: $primary;
      button {
        height: 40px;
        width: 40px;
        &:disabled {
          cursor: not-allowed;
        }
        svg {
          height: 100%;
          width: 100%;
          color: white;
        }
        padding: 0;
      }
      .buttonAndTitle {
        display: grid;
        grid-gap: $gutter;
        grid-template-columns: 1fr 2fr;
        @include lessThan(sm) {
          grid-template-columns: 1fr 4fr;
        }
        align-items: center;
      }
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .right {
      @include lessThan(md) {
        display: none;
      }
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $primary;
      p {
        padding: $gutter/2 $gutter;
        border: 1px solid white;
        text-transform: uppercase;
        font-size: $font-size-sm;
      }
    }
    justify-content: space-between;
    p {
      color: white;
      margin: 0;
    }
    .processorWrapper {
      display: none;
    }
    button {
      cursor: pointer;
      outline: none;
      background-color: transparent;
      border: none;
      color: white;
      font-size: $font-size-lg;
    }
    audio {
      display: none;
    }
  }
  #mixcloud {
    display: grid;
    grid-template-columns: 4fr 1fr;
    @include lessThan(lg) {
      grid-template-columns: 2fr 1fr;
    }
    height: 60px;
    background-color: white;
    .right {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $black;
      padding: 0 $gutter;
      button {
        background-color: transparent;
        text-transform: uppercase;
        border: none;
        color: white;
        font-size: $font-size-md;
      }
    }
  }
}
