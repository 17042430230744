.mosaique {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .tile {
    margin: $gutter/2;
    width: calc((100% / 4) - 16px);
    @include lessThan(lg) {
      width: calc((100% / 3) - 16px);
    }
    @include lessThan(md) {
      width: calc((100% / 2) - 16px);
    }
  }
  @supports (display: grid) {
    display: grid;
    grid-gap: $gutter;
    grid-template-columns: repeat(2, 1fr);
    @include moreThan(md) {
      grid-template-columns: repeat(4, 1fr);
    }
    .tile {
      margin: 0;
      width: auto;
    }
  }
}
