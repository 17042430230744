#privacy {
  .content {
    & > * {
      padding: $gutter * 2 0;
    }
    p {
      font-size: $font-size-lg;
    }
    .title {
      max-width: 50%;
      @include lessThan(lg) {
        max-width: none;
      }
      margin: 0 auto;
      text-align: center;
      text-transform: uppercase;
    }
    .paragraph {
      max-width: 50%;
      letter-spacing: 0.1px;
      @include lessThan(lg) {
        max-width: none;
        padding: $gutter;
      }
      margin: 0 auto;
      text-align: center;
    }
  }
}
