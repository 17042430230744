@font-face {
  font-family: "ParetoTriangular";
  src: url("/fonts/pareto_triangular-webfont.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "favorit";
  src: url("/fonts/FavoritLight/favorit-lyl-light.woff2") format("woff2"),
    url("/fonts/FavoritLight/favorit-lyl-light.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "favorit";
  src: url("/fonts/FavoritMedium/favorit-lyl-medium.woff2") format("woff2"),
    url("/fonts/FavoritMedium/favorit-lyl-medium.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "favorit";
  src: url("/fonts/FavoritLightItalic/favorit-lyl-light-italic.woff2") format("woff2"),
    url("/fonts/FavoritLightItalic/favorit-lyl-light-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}
