@import "reset";
@import "variables";
@import "text";
@import "fonts";
@import "mixins";
@import "components/index";
@import "pages/index";

body {
  font-family: "favorit", "Arial", "sans-serif";
  font-size: $font-size-md;
  color: $black;
}

#app {
  padding-bottom: $gutter * 6;
}

img {
  max-width: 100%;
}
