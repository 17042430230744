#episode {
  padding: $gutter;
  .content {
    display: grid;
    grid-gap: $gutter;
    grid-template-columns: 1fr 1fr;
    @include lessThan(lg) {
      grid-template-columns: 1fr;
    }
    .left {
      img {
        position: sticky;
        position: -webkit-sticky;
        top: $bar-height;
      }
    }
    & > .right {
      .header {
        border-top: 1px solid black;
        border-bottom: 1px solid black;
        padding: $gutter;
        .playerAndTitle {
          display: grid;
          grid-template-columns: 1fr 3fr;
          .playButton {
            svg {
              padding-right: $gutter;
              width: 80px;
              height: auto;
              color: black;
            }
          }
          .right {
            & > * {
              margin-bottom: $gutter;
            }
            .date {
              p {
                font-size: $font-size-sm;
              }
            }
            h1,
            h2 {
              font-size: $font-size-lg;
              font-weight: 300;
              text-transform: uppercase;
            }
            h2 {
              font-size: $font-size-md;
            }
          }
        }
      }
      .description {
        padding: $gutter;
        p {
          margin-top: $gutter * 2;
          padding: 0 $gutter * 4;
          @include lessThan(md) {
            padding: 0;
          }
          text-align: center;
        }
        .tags {
          padding: $gutter * 2;
          @include lessThan(md) {
            padding: $gutter/2;
          }
          justify-content: center;
          display: flex;
          flex-wrap: wrap;
          .tag {
            font-size: $font-size-md;
          }
        }
      }
      .tracklist {
        ul {
          li {
            border-top: 1px solid black;
            padding: $gutter 0;
            font-size: $font-size-sm;
            @include lessThan(md) {
              font-size: $font-size-xs;
              padding: $gutter/2 0;
            }
          }
          &:last-child {
            border-bottom: 1px solid black;
          }
        }
      }
      .linksAndLink {
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-transform: uppercase;
        padding: $gutter 0;
        a {
          font-size: $font-size-sm;
        }
        .links {
          justify-content: flex-start;
          .link {
            padding: 0;
            margin-right: $gutter;
            svg {
              width: auto;
              height: 14px;
            }
          }
        }
      }
    }
  }
  .suggestions {
    margin-top: $gutter * 4;
    border-top: 1px solid black;
    padding: $gutter 0;
    h2 {
      font-family: "ParetoTriangular";
      text-transform: uppercase;
    }
  }
}
