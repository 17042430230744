#article {
  .content {
    & > * {
      padding: $gutter * 2 0;
    }
    p {
      font-size: $font-size-lg;
    }
    .title {
      max-width: 50%;
      @include lessThan(lg) {
        max-width: none;
      }
      margin: 0 auto;
      text-align: center;
      text-transform: uppercase;
    }
    .authors {
      max-width: 50%;
      @include lessThan(lg) {
        max-width: none;
      }
      margin: 0 auto;
      text-align: center;
      h2 {
        font-size: $font-size-sm;
      }
      text-transform: uppercase;
    }
    .episode {
      max-width: 50%;
      margin: 0 auto;
      @include lessThan(lg) {
        max-width: none;
        padding: $gutter;
      }
      border-top: 1px solid black;
      border-bottom: 1px solid black;
      .playerAndTitle {
        display: grid;
        grid-template-columns: 1fr 3fr;
        .playButton {
          svg {
            padding-right: $gutter;
            width: 80px;
            height: auto;
            color: black;
          }
        }
        .right {
          & > * {
            margin-bottom: $gutter;
          }
          .date {
            p {
              font-size: $font-size-sm;
            }
          }
          h1,
          h2 {
            font-size: $font-size-lg;
            font-weight: 300;
            text-transform: uppercase;
          }
          h2 {
            font-size: $font-size-md;
          }
        }
      }
    }
    .paragraph {
      max-width: 50%;
      letter-spacing: 0.1px;
      @include lessThan(lg) {
        max-width: none;
        padding: $gutter;
      }
      margin: 0 auto;
      text-align: center;
    }
    .tracklist {
      max-width: 50%;
      letter-spacing: 0.1px;
      .track {
        p,
        a {
          font-size: $font-size-sm;
          align-items: center;
          display: flex;
          svg {
            margin-right: 5px;
          }
        }
      }

      @include lessThan(lg) {
        max-width: none;
        padding: $gutter;
      }
      margin: 0 auto;
      text-align: left;
    }
    .image {
      max-width: 50%;
      @include lessThan(lg) {
        max-width: none;
      }
      margin: 0 auto;
    }
    .interview {
      max-width: 50%;
      @include lessThan(lg) {
        max-width: none;
        padding: $gutter;
      }
      margin: 0 auto;
      .question {
        font-weight: bold;
      }
    }
    .quote {
      max-width: 50%;
      @include lessThan(lg) {
        max-width: none;
      }
      margin: 0 auto;
      text-align: center;
      text-transform: uppercase;
    }
    .widget {
      max-width: 50%;
      margin: 0 auto;
      iframe {
        width: 100%;
      }
      @include lessThan(lg) {
        max-width: none;
      }
    }
    .by {
      max-width: 50%;
      @include lessThan(lg) {
        max-width: none;
      }
      margin: 0 auto;
      margin-top: $gutter * 2;
      text-align: left;
      h2 {
        font-size: $font-size-sm;
      }
    }
  }
}
