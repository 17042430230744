.searchbar {
  display: flex;
  border-style: solid;
  border-color: $black;
  border-bottom-width: .5px;

  input {
    border-width: 0;
    font-size: $font-size-xl;
    color: $black;
    width: 100%;

    @include placeholder {
      font-family: "ParetoTriangular";
      opacity: 1;
    }

    &:focus {
      outline: none;
      @include placeholder {
        font-family: "favorit";
        color: $grey;
      }
    }
  }

  #cancel {
    font-size: $font-size-lg;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }

}

