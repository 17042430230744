nav#navbar {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  padding-left: $gutter;
  padding-right: $gutter;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
  height: $bar-height;
  a:hover {
    border-bottom: 1px dotted $black;
  }

  .left {
    ul {
      display: flex;
      align-items: center;
      li {
        font-size: $font-size-md;
        padding: 0 $gutter / 2;
        &.active {
          font-weight: bold;
        }
      }
    }
  }

  .middle {
    font-family: "ParetoTriangular";
    text-align: center;
    a:hover,
    a:active {
      text-decoration: none;
    }
    h1 {
      font-size: $font-size-lg;
      margin: 0;
    }
  }

  .right {
    display: flex;
    justify-content: flex-end;
    text-align: right;
  }
}

#mobileNavbar {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 3;
  height: $bar-height;
  .top {
    padding: $gutter/2 $gutter;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    @include lessThan(md) {
      grid-template-columns: 1fr 2fr 1fr;
    }
    .search {
      text-align: right;
    }
    .burger {
      button {
        padding: 0;
        height: 30px;
        width: 30px;
        align-content: center;
        outline: none;
        svg {
          height: 100%;
          width: 100%;
        }
        background-color: transparent;
        border: none;
      }
    }
    .middle {
      font-family: "ParetoTriangular";
      text-align: center;
      a:hover,
      a:active {
        text-decoration: none;
      }
      h1 {
        font-size: $font-size-lg;
        margin: 0;
      }
    }
  }
  .bottom {
    overflow-y: hidden;
    ul {
      padding: $gutter/2 $gutter;
      display: flex;
      justify-content: space-between;
      align-items: center;
      li {
        font-size: $font-size-md;
      }
    }
    transition: height 0.5s cubic-bezier(0, 1, 0, 1);
    &.open {
      height: 30px;
    }
    &.closed {
      height: 0px;
    }
  }
}

#mobileMenu {
  position: absolute;
  left: 0;
  top: 0;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 2;
  height: 100vh;
  color: white;
  background-color: $black;
  position: fixed;
  .content {
    margin: $gutter * 5 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: $gutter;
    ul {
      li {
        padding: $gutter/2 0;
        text-transform: uppercase;
        font-size: 30px;
      }
    }
    .icons {
      margin-top: 150px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .donate {
        border: 1px solid white;
        padding: 5px;
      }
      a {
        padding: 0;
      }
      ul {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        li {
          padding: 0 $gutter/2;
          font-size: $font-size-md;
        }
      }
    }
  }
  &.show {
    transform: translateX(0%);
    transition: transform 0.3s ease-out;
  }
  &.hide {
    transform: translateX(-100%);
    transition: transform 0.3s ease-in;
  }
}

.icons {
  a.donate {
    text-transform: uppercase;
    padding: 2px 7px;
    font-size: $font-size-sm;
    border: 1px solid $black;
  }
  display: flex;
  & > * {
    padding: 0 $gutter/2;
  }
}
