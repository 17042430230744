#shows {
  section {
    margin-bottom: $gutter * 2;
  }
  .filters {
    display: block;
    .styles,
    .studios {
      display: flex;
      justify-content: center;
      .studio {
        text-transform: capitalize;
      }
      li {
        padding: $gutter;
        font-size: $font-size-sm;
        button {
          padding: $gutter/4 $gutter/2;
          border-radius: $gutter;
          &:hover {
            cursor: pointer;
          }
          &.active {
            background-color: $black;
            color: white;
          }
          outline: none;
          font-size: $font-size-md;
          font-weight: normal;
          background-color: transparent;
          border: none;
        }
      }
    }
    .studios {
      border-color: black;
      border-width: 1px;
      border-top-style: solid;
      border-bottom-style: solid;
      p {
        margin: 0;
      }
    }
    .styles {
      border-color: black;
      border-width: 1px;
      border-bottom-style: solid;
      @include lessThan(lg) {
        & {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          padding: $gutter/2;
          li {
            text-align: center;
            padding: 0;
          }
        }
      }
    }
    a {
      background-color: transparent;
      border: none;
      font-size: $font-size-md;
      padding: 0 $gutter/2;
      outline: none;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
      &.active {
        text-decoration: underline;
      }
    }
  }
  .entries {
    padding: $gutter;
  }
}

#show {
  .separator {
    margin-left: $gutter;
    margin-right: $gutter;
    border-style: solid;
    border-color: $black;
    border-top-width: 1px;
  }

  .content {
    display: grid;
    grid-gap: $gutter;
    grid-template-columns: 1fr;

    .header {
      margin-top: $gutter;
      text-align: center;
      h1 {
        font-weight: medium;
        font-size: $font-size-xl;
        margin: $gutter * 2 auto;
        max-width: 50%;
        @include lessThan(lg) {
          max-width: 100%;
        }
        color: $black;
        text-align: center;
      }
    }
    img {
      width: 100%;
    }
    .description {
      max-width: 50%;
      @include lessThan(sm) {
        max-width: 100%;
      }
      color: $black;
      h2 {
        margin-bottom: $gutter * 2;
        text-transform: uppercase;
        font-size: $font-size-lg;
      }
      p {
        font-size: $font-size-lg;
        font-weight: 300;
        letter-spacing: 0.1;
        font-family: "favorit";
      }
      margin: 0 auto;
      padding: $gutter * 2;
      text-align: center;
    }
    .nextBroadcast {
      h2 {
        font-weight: bold;
        font-size: $font-size-xl;
        text-align: center;
        margin: 0;
        text-transform: uppercase;
      }
      padding: $gutter * 2;
      border: 1px solid $black;
    }
    .episodes {
      padding: $gutter;
    }
  }
}
