:root {
  --primary: #e94e24;
  --secondary: #79fece;
  --green: green;
  --red: red;
  --orange: orange;
  --blue: blue;
  --brown: #c07111;
  --white: #ffffff;
  --black: #1d1d1b;
  --grey: #dadada;
  --grey-light: #f0f0f0;
  --grey-dark: #7f7f7f;
}

$primary: var(--primary);
$secondary: var(--secondary);
$green: var(--green);
$red: var(--red);
$orange: var(--orange);
$blue: var(--blue);
$white: var(--white);
$black: var(--black);
$brown: var(--brown);
$grey: var(--grey);
$grey-light: var(--grey-light);
$grey-dark: var(--grey-dark);

$success: $green;
$warning: $orange;
$danger: $red;
$info: $blue;

$bar-height: 60px;

$gutter: 16px;

$font-size-xs: 10px;
$font-size-sm: 12px;
$font-size-md: 15px;
$font-size-lg: 20px;
$font-size-xl: 30px;
$font-size-xxl: 40px;
