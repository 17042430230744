#home {
  img {
    width: 100%;
  }
  section {
    h2 {
      @extend .pareto;
      text-transform: uppercase;
    }
  }
  #editorschoice,
  #latest,
  .posts {
    margin: $gutter;
    padding-top: $gutter;
    padding-bottom: $gutter;
    border-bottom: 1px solid $black;
  }
  .schedule {
    #schedule {
      margin: 0 $gutter;
    }
    .header {
      display: flex;
      justify-content: flex-start;
      align-items: baseline;
      h1 {
        margin: $gutter;
        font-family: "ParetoTriangular";
      }
      p {
        font-size: $font-size-sm;
        margin: 0;
        margin-left: $gutter;
      }
    }
  }
  .posts {
    .header {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      h1 {
        font-family: "ParetoTriangular";
      }
      p {
        font-size: $font-size-sm;
        margin: 0;
        margin-left: $gutter;
      }
    }
  }
}
