.links {
  display: flex;
  justify-content: center;
  .link {
    padding: $gutter;
    a {
      svg {
        width: 20px;
        height: 20px;
        color: $black;
      }
    }
  }
}
