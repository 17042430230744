.tile {
  display: flex;
  flex-direction: column;
  .tileImage {
    height: 150px;
    position: relative;
    @include moreThan(md) {
      height: 150px;
    }
    @include moreThan(lg) {
      height: 200px;
    }
    @include moreThan(xl) {
      height: 250px;
    }
    @include moreThan(xxl) {
      height: 250px;
    }
    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
    &:hover {
      .playButton {
        display: block;
      }
    }
    .playButton {
      position: absolute;
      top: 0;
      left: 0;
      display: none;
      width: 100%;
      height: 100%;
      padding: $gutter;
      svg {
        color: white;
        width: 100%;
        height: 100%;
      }
    }
  }
  .tileInfos {
    padding: 6px;
    padding-top: 8px;
    .title {
      text-transform: uppercase;
      line-height: 1.2;
      letter-spacing: 0.5px;
    }
    .artists {
      font-size: 14px;
    }
    .date {
      text-transform: uppercase;
      font-size: $font-size-sm;
      letter-spacing: 0.5px;
      margin-bottom: $gutter/4;
    }
    .tags {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .nextBroadcast {
    display: flex;
    justify-content: space-between;
    p {
      text-transform: uppercase;
      font-size: $font-size-sm;
    }
  }
  &.hover {
    cursor: pointer;
    background-color: $black;
    color: white;
    .tileInfos {
      a:hover {
        border-bottom-color: white;
      }
    }
    .tags {
      .tag {
        border-color: white;
      }
    }
  }

  &.post {
    .titleHeader {
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 0.5px;
      margin-bottom: 4px;
    }
    .title a {
      text-transform: none;
      letter-spacing: 0.7px;
      font-weight: 300;
    }
  }
}
