#about {
  display: grid;
  grid-template-columns: 1fr 1fr;
  @include lessThan(lg) {
    grid-template-columns: 1fr;
  }
  .left {
    padding: $gutter;
    img {
      margin-top: $gutter;
    }
    background-color: $grey-light;
    h1 {
      text-align: center;
      padding: $gutter * 2;
      text-transform: uppercase;
      font-family: "ParetoTriangular";
    }
    #contacts {
      border-bottom: 1px solid $black;
      .contact {
        &:hover {
          color: white;
          background-color: black;
        }
        font-size: $font-size-sm;
        display: grid;
        grid-template-columns: 1fr 1.5fr 1fr;
        border-top: 1px solid $black;
        padding: $gutter/1.5;
      }
    }
  }
  .right {
    padding: $gutter;
    h2 {
      text-align: center;
      margin: $gutter * 3 0;
      letter-spacing: 0.75px;
      font-size: $font-size-md;
      text-transform: uppercase;
    }
    p {
      font-size: $font-size-md;
      line-height: 20px;
      letter-spacing: 0;
      margin-bottom: $gutter;
    }
    strong {
      font-family: "ParetoTriangular";
    }
  }
}
